// 
// page: contact
// 

ul.contact-data-list{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        left: -50vw;
        top: -70px;
        bottom: 0px;
        width: 150vw;
        background-color: $gray-600;
        z-index: -1;
    }
    margin: 0 -40px;
    > li{
        padding: 40px;
        padding-right: 0;
        border-bottom: 1px solid $gray-200;
    }
    > li:last-child{
        border:0px;
    }
}
.contact-data-title{
    margin-bottom: 50px;
}

.contact-data-item{
    display: flex;
    flex-wrap: wrap;
    .data-item-address{
        flex: 0 0 35%;
        p{
            margin: 0;
            font-size: 16px;
            font-weight: 300;
        }
    }
    .data-item-link{
        flex: 0 0 65%;
        display: flex;
        
        .data-intem-link-col{
            flex: 0 0 50%;

            a{
                display: block;
                font-size: 18px;
                color: #a8a7a7;
                font-weight: 500;
                position: relative;
                padding-left: 32px;
                margin: 10px 0;
                
                [class^="icon"]{
                    position: absolute;
                    left: 0;
                    top: 5px;
                    color: $dark;
                }
                .icon-smartphone-call{
                    font-size: 24px;
                    top: 1px;
                    left: 5px;
                }
                .icon-envelope-2{
                    top: 5px;
                }
            }
        }
    }
}

.form-text{
    padding: 40px 0;
    font-size: 16px;
}

.form-container-pull{
    margin: 40px -40px;
}

.g-map-container{
    margin-left: -115px;
}



@include media-breakpoint-down(lg) {
    .contact-data-title{
        margin-bottom: 20px;
    }
    .contact-data-item{
        display: block;
        .data-item-link{
            display: block;
        }
    }
}

@include media-breakpoint-down(md) {
    ul.contact-data-list{
        margin: 0;
        &:after{
            content: none;
        }
        > li{
            padding: 15px;
        }
    }
    .form-text{
        padding: 10px 0;
    }
    .form-container-pull{
        margin-left: 0;
        margin-right: 0;
    }

    .g-map-container{
        margin-left: 0;
    }
}





