@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Black.woff2") format("woff2"),
    url("../fonts/Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"),
    url("../fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Medium.woff2") format("woff2"),
    url("../fonts/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Light.woff2") format("woff2"),
    url("../fonts/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Gotham Book";
  font-weight: 300;
  font-style: normal;
  color: $dark;
  font-size: 14px;
}

.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: bold;
}

.font-gotham {
  font-family: "Gotham Book";
  font-weight: normal;
  font-style: normal;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Gotham";
  color: $dark;
}

h1,
.h1 {
  font-size: 72px;
  font-weight: bold;
}

h2,
.h2 {
  font-size: 28px;
  font-weight: 500;
}

h3,
.h3 {
  font-size: 28px;
}

h4,
.h4 {
  font-size: 26px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 18px;
}

p,
ul ol {
  font-size: 14px;
}
.title {
  h2,
  h3 {
    margin: 0;
    line-height: 1;
  }
  h2 {
    font-family: "Gotham Book";
    font-size: 46px;
  }
  h3 {
    font-weight: 300;
    font-size: 42px;
  }
  margin-bottom: 30px;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
  text-decoration: none;
  outline: none;
}

a {
  color: $dark;
  transition: all 0.35s;
}

a:hover,
a:focus {
  color: $primary;
}

@include media-breakpoint-down(xl) {
  h1,
  .h1 {
    font-size: 64px;
  }
  .title {
    h2 {
      font-size: 44px;
    }
    h3 {
      font-size: 32px;
    }
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
}

@include media-breakpoint-down(lg) {
  h1,
  .h1 {
    font-size: 44px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  .title {
    h2 {
      font-size: 44px;
    }
    h3 {
      font-size: 26px;
    }
  }
}

@include media-breakpoint-down(md) {
  h1,
  .h1 {
    font-size: 36px;
  }
  .title {
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 22px;
    }
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
}

@include media-breakpoint-down(sm) {
  h1,
  .h1 {
    font-size: 30px;
  }
  h2,
  .h2 {
    font-size: 20px;
  }
  .title {
    margin-bottom: 15px;
  }
}
