// 
// Article
// 

article{
    h1, h2, h3, h4, h5, h6{
        margin: 60px 0;
    }
    p{
        margin: 40px 0;
        font-weight: 300;
        font-size: 16px;
    }
    .article-pull{
        margin-left: -10%;
        margin-right: -10%;
    }
    .boxed{
        margin-left: -40px;
        margin-right: -40px;
    }
}

.article-more{
    text-align: center;
    margin: 40px auto;
    text-align: center;
    border:1px solid $gray-200;
    padding: 40px;
    p{
        font-size: 20px;
        font-weight: 500;
        .btn{
            margin-top: 20px;
        }
    }
}

.boxed{
    padding: 30px 35px;
    border:5px solid $gray-800;
}
.partners-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    > li{
        padding: 10px;
        flex: 0 1 50%;
    }
}

.article-single{
    .article-content{
        padding: 40px 15%;
    }
    p{
        font-size: 16px;
    }
    .article-meta{
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 20px;
    }
    h2:first-of-type{
        margin-top: 0;
    }
}

@include media-breakpoint-down(md) {
    article{
        h1, h2, h3, h4, h5, h6{
            margin: 30px 0;
        }
        p{
            margin: 20px 0;
        }
        .article-pull{
            margin-left: 0%;
            margin-right: 0%;
        }
        .boxed{
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .article-single .article-content{
        padding: 40px 0;
    }

    .article-more{
        margin: 10px auto;
        padding: 20px;
        p{
            font-size: 18px;
        }
    }
}

@include media-breakpoint-down(sm) {}


