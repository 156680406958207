// 
// List
// 
ul,
ol {
    @extend .list-unstyled;
}

// list base
ul.list-base {
    font-size: 15px;
    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
        &:before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
}

// list bullet
ul.list-bullet {
    li {
        position: relative;
        padding-left: 20px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 6px;
            height: 6px;
            background-color: $black;
            border-radius: 50%;
        }
    }
}

//
// Lists decimal
//
ul.list-decimal{
    counter-reset: counter-decimal;
    font-size: 16px;
    > li{
        position: relative;
        padding-left: 35px;
        margin-bottom: 10px;
        &:before{
            position: absolute;
            left: 0;
            top: -1px;
            content: counter(counter-decimal, decimal)  ".";
            counter-increment: counter-decimal;
            font-weight: 600;
            color: $gray-300;
        }
    }
}

//
// list download
//
ul.list-download{
    > li{
        margin-bottom: 4px;
    }
    > li:last-child{
        margin-bottom: 0;
    }
}

.download-item{
    font-size: 14px;
    padding: 20px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-100;
    
    .download-text, .download-link{
        font-weight: 400;
        font-size: 14px;
        transition: all 0.3s;
    }
    
    .download-link{
        display: flex;
        padding: 6px 0;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        position: relative;
        text-transform: uppercase;
        [class^="icon"]{
            margin-right: 10px;
            font-size: 18px;
        }
    }

    
    .download-item:hover{
        .download-text, .download-link{
            color: $primary;
        }
    }
}


@include media-breakpoint-down(lg) {
    ul.list-bullet li{
        margin-bottom: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .download-item{
        padding: 10px;
        display: block;
        .download-link{
            display: block;
            margin-top: 10px;
        }
    }
}

