// 
// Aos
// 


[data-aos="offer-animation"] {
    @media screen and (min-width: 1200px) {
        .offer-item{
            .offer-image{
                > .image-holder{
                    transform: translateX(100px);
                    opacity: 0;
                    transition: all 0.35s 0.25s;
                }
                > .image-small .image-holder{
                    transform: translateX(100px);
                    opacity: 0;
                    transition: all 0.35s 0.5s;
                }
                
            }
            .offer-content{
                transform: translateX(-100px);
                opacity: 0;
                transition: all 0.35s 0.25s;
            }
        }
        
        
        &.aos-animate {
            .offer-item{
                .offer-image{
                    > .image-holder{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                    > .image-small .image-holder{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }
                .offer-content{
                    transform: translateX(0px);
                    opacity: 1;
                }
            }
        }
    }
}


[data-aos="section-home-animation"] {
    .h1{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.35s 0.3s;
    }
    .offer-image{
        > .image-holder{
            opacity: 0;
            transform: translateX(-100px);
            transition: all 0.35s 0.4s;
        }
        .image-small .image-holder{
            opacity: 0;
            transform: translateX(-100px);
            transition: all 0.35s 0.6s;
        }
    }

    .offer-content-inner{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.35s 0.9s;
    }

    &.aos-animate{
        .h1{
            opacity: 1;
            transform: translateX(0px);
        }
        .offer-image{
            > .image-holder{
                opacity: 1;
                transform: translateX(0px);
            }
            .image-small .image-holder{
                opacity: 1;
                transform: translateX(0px);
            }
        }
        .offer-content-inner{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}