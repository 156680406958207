.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $white;
  z-index: 999;
  overflow: hidden;

  .header-row {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
    height: 100px;
    transition: all 0.3s;
  }

  .header-brand {
    width: 200px;
    img {
      width: 200px;
      transition: all 0.3s;
      margin-top: 10px;
    }
  }

  .header-nav {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding-left: 30px;
    li {
      padding: 0 18px;
    }
    li a {
      text-transform: uppercase;
      color: $dark;
      font-size: 15px;
      font-weight: 700;
      &:hover {
        color: $primary;
      }
    }
  }
  .header-social {
    display: flex;
    padding-right: 20px;
    margin: 0;
    margin-top: 6px;
    li {
      padding: 0 10px;
    }
    li a {
      text-transform: uppercase;
      color: $dark;
      font-size: 26px;
      &:hover {
        color: $secoundary;
      }
    }
  }
  .header-lang {
    width: 115px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-transform: uppercase;
    color: $dark;
    font-size: 14px;
    font-weight: 700;
    align-self: stretch;
    transition: all 0.3s;
    background-color: $black;

    a {
      color: $white;
      &:hover {
        color: $secoundary;
      }
    }
  }

  .header-button {
    margin-left: 30px;
    margin-right: 30px;
      .btn {
        white-space: nowrap;
    }
  }

  .hamburder-container {
    display: none;
  }
}
.header.header-sticky {
  .header-row {
    // padding: 0px 0px 0px 20px;
    height: 70px;
  }
  border-bottom: 1px solid $gray-200;
  .header-brand {
    img {
      width: 120px;
    }
  }
  .header-lang {
    border-bottom-color: transparent;
  }
}

@include media-breakpoint-down(xl) {
  .header {
    .header-nav {
      > li {
        padding: 0 6px;

        > a {
          font-size: 13px;
        }
      }

      padding-left: 15px;
    }
    .header-social {
      padding-right: 8px;
      li {
        padding: 0 5px;
      }
    }

    .header-lang {
      flex-shrink: initial;
    }

    .header-button {
       margin-left: 8px;
        margin-right: 8px;
       .btn {

       }
    }
  }
}

@include media-breakpoint-down(lg) {
  .header {
    padding: 0px 0px 0px 20px;
    .header-nav,
    .header-social,
    .header-button {
      display: none;
    }
    .hamburder-container {
      display: block;
      margin-left: auto;
      margin-right: 25px;
    }
  }
  .header,
  .header.header-sticky {
    .header-row {
      height: 60px;
      padding: 0px;
      justify-content: space-between;
    }
    border-bottom: 1px solid $gray-200;
    .header-brand {
      img {
        width: 120px;
      }
    }
    .header-lang {
      border-bottom-color: transparent;
    }
  }
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(sm) {
  .header,
  .header.header-sticky {
    .header-lang {
      width: 60px;
    }
    // .header-row {
    //   padding: 10px 80px 10px 10px;
    // }
  }
}
