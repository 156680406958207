// 
// page: blog
//

.list-post{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        left: calc( 100% - 40px );
        top: -50vh;
        bottom: 90px;
        width: 150vw;
        background-color: $gray-600;
        z-index: -1;
        min-height: 900px;
    }
}

.article-single{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        left: calc( 100% - 40px );
        top: -50vh;
        bottom: 120px;
        width: 100vw;
        background-color: $gray-600;
        z-index: -1;
    }
}


@include media-breakpoint-down(md) {
    .list-post:after, .article-single:after{
        content: none;
    }
}