// 
// page: reference
// 

.list-refernce{
    > li{
        margin-bottom: 30px;
    }
}
.reference-item{
    display: block;
    border:1px solid $gray-200;
    position: relative;
    height: 100%;
    text-align: center;
    
    &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0px;
        transition: all 0.2s;
        background-color: $primary;
    }
    .reference-image{
        position: relative;
        padding: 40px 20%;
        padding-bottom: 20px;
        img{
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        }
    }
    .reference-title{
        padding: 20px;
        h5{
            font-weight: 500;
        }
    }
    .reference-icon{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 60px;
        height: 60px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
        font-size: 24px;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.3);
        transition: all 0.3s;
    }
}

.reference-item:hover{
    &:after{
        height: 4px;
    }
    .reference-title{
        h5{
            color: $primary;
        }
    }
    .reference-icon{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}