.page {
  overflow: hidden;
  &-title {
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h1,
    .h1 {
      margin-bottom: 20px;
      padding-left: 20px;
    }
  }
}

.bg-content {
  background-color: $gray-600;
  padding: 50px 0;
  position: relative;
  > p:first-of-type {
    margin-top: 0;
  }
  &:after {
    content: "";
    position: absolute;
    left: -50vw;
    width: 150vw;
    top: 0;
    bottom: 0;
    background-color: $gray-600;
    z-index: -1;
  }
}

.bg-content-white {
  background-color: $white;
  padding: 50px 0;
  position: relative;
  > p:first-of-type {
    margin-top: 0;
  }
}

.bg-content-top {
  top: -70px;
}
.bg-half {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: -70px;
    left: -50vw;
    width: 150vw;
    height: 900px;
    background-color: $gray-600;
    z-index: -1;
  }
}

.page-no-hero {
  .page {
    padding-top: 115px;
  }
}

.page-title {
  h1 {
    font-family: "Gotham Book";
    font-size: 46px;
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    background-color: $secoundary;
    margin: 10px 0;
    padding: 8px 40px;
  }
}

.box-title {
  display: inline-block;
  font-family: "Gotham Book";
  font-size: 46px;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  background-color: $secoundary;
  margin: 10px 0;
  padding: 8px 40px;
}

@include media-breakpoint-down(lg) {
  .page {
    padding: 60px 0px 0px 0px;
  }
  .page-no-hero .page {
    padding: 90px 0px 0px 0px;
  }
  .page-title {
    padding-bottom: 0px;
  }
}

@include media-breakpoint-down(md) {
  .bg-half:after {
    content: none;
  }
  .page-title {
    h1 {
      padding-left: 0;
      margin: 0;
      padding: 10px;
      margin-bottom: 20px;
    }
  }

  .page-title {
    h1 {
      font-size: 32px;
      padding: 8px 20px;
    }
  }

  .box-title {
    font-size: 32px;
    padding: 8px 20px;
  }

  .page-no-hero .page-title h1 {
    padding-left: 0;
  }
}
