// 
// Sidebar
// 
.sidebar {
    padding-top: 40px;
    position: relative;
    width: 100%;
    
   
    .widget + .widget{
        margin-top: 45px;
    }
    .widget-title{
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 500;
    }
    .title-small{
        margin-bottom: 15px;
    }
}

// 
// widget menu
// 
ul.widget-menu{
    padding: 0;
    margin: 0;
    li{
        margin-bottom: 4px;
    }
    li:last-child{
        margin-bottom: 0;
    }
    li a{
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        display: inline-block;
        position: relative;
        overflow: hidden;
        color: 757474;
    }
    li a.active, li a:hover{
        color: $primary;
    }
}

// 
// Recent posts
// 
ul.recent-posts {
    li {
        padding: 15px 0;
        border-bottom: 1px solid $gray-200;
    }
    li:first-child{
        padding-top: 0;
    }
    li:last-child{
        border:0px;
    }
}

.recent-item {
    display: flex;
    .recent-image {
        width: 80px;
        flex: 0 0 80px;
    }
    .recent-content {
        padding: 5px 15px;
        padding-right: 0;
        padding-top: 15px;
        h6,
        p {
            transition: color 0.3s;
        }
        h6 {
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            color: $dark;
            margin-bottom: 5px;
        }
        p {
            font-weight: 300;
            font-size: 13px;
            margin: 0;
            color: $dark;
            line-height: 1.2;
        }
    }
}

.recent-item:hover {
    h6,
    p {
        color: $primary;
    }
}



@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {
    .sidebar{
        padding: 40px 0px;
        background-color: $gray-600;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            left: -100px;
            right: -100px;
            top: 0;
            bottom: 0;
            background-color: $gray-600;
            z-index: -1;
        }
    }
}

