//
// Footer
//
.footer {
  position: relative;
  background-color: $black;
  overflow: hidden;
  color: rgba($white, 0.6);

  a:not(.btn) {
    color: rgba($white, 0.6);
    &:hover {
      color: $secoundary;
    }
  }

  .t-white {
    color: $white;
  }

  p,
  ul {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0px 40px 0px;
  }

  .copyright-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-row-group {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  .footer-brand {
    margin-right: auto;
    padding-top: 15px;
    img {
      max-width: 190px;
    }
  }

  ul.footer-menu {
    margin: 0;
    display: flex;
    li {
      padding: 0 15px;
    }
    li:last-child {
      padding-right: 0;
    }
    li a {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .footer-address {
    padding: 0 25px;
  }

  .footer-social-list {
    display: flex;
    justify-content: center;
    li {
      padding: 0 10px;
    }
    li a {
      color: $white;
      font-size: 26px;
      &:hover {
        color: $secoundary;
      }
    }
  }

  .footer-contact {
    padding-left: 25px;
  }

  .footer-copyright {
    border-top: 1px solid rgba($white, 0.1);
    padding: 30px 0;
    .copyright-text {
      p {
        color: $dark;
      }
      a {
        color: $gray-500;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .footer {
    ul.footer-menu li {
      padding: 0 7px;
    }
    .footer-social-list {
      li {
        padding: 0 5px;
      }
    }
    .footer-address {
      padding: 0 15px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .footer {
    .footer-copyright {
      .copyright-text a {
        display: block;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    .footer-row,
    .footer-row-group,
    .copyright-row {
      display: block;
      text-align: center;
    }
    .footer-brand,
    .footer-address {
      margin-right: 0;
      margin-bottom: 20px;
    }
    ul.footer-menu {
      justify-content: center;
    }
    .footer-social-list {
      margin: 20px 0;
    }
    .footer-contact {
      padding-left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    .footer-contact-data {
      a + a {
        margin: 5px 0;
      }
    }
    ul.footer-menu {
      margin-top: 20px;
      display: block;
      text-align: center;
      > li {
        padding: 5px;
      }
    }
  }
}
