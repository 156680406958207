//
// FIX olw mobile scroll
//
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: auto;
    touch-action: auto;
}

.article-gallery{
    position: relative;
    padding: 20px 0;
    .owl-dots{
        display: none;
    }
    .owl-nav{
        position: absolute;
        right: -90px;
        bottom: 0;
    }
}

.single-gallery{
    padding: 0 80px;
    .owl-prev, .owl-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
    }
    .owl-prev{
        left: 0;
    }
    .owl-next{
        right: 0;
    }
}


@include media-breakpoint-down(md) {
    .article-gallery{
        .owl-nav{
            position: static;
            display: flex;
            justify-content: flex-end;
        }
    }
}

@include media-breakpoint-down(sm) {}


