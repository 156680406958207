//
// Image holder
//
.image-holder {
  display: block;
  position: relative;
  z-index: 10;
  overflow: hidden;
  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $gray-200;
    background-size: cover;
    background-position: 50%;
  }
  &-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 20%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0;
    bottom: 40px;
    display: flex;
    justify-content: center;
    h3 {
      background-color: $secoundary;
      display: inline-block;
      padding: 24px;
      color: $white;
      font-weight: 500;
      font-size: 28px;
      color: $black;
      text-transform: uppercase;
      transition: all 0.3s;
    }
  }

  &-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 18px;
    color: $dark;
    opacity: 0;
    transform: scale(0.4);
    transition: all 0.3s;
  }
}

.image-holder:hover {
  .image-holder-icon {
    transform: scale(1);
    opacity: 1;
  }

  h3 {
    background-color: $black;
    color: $white;
  }
}

//
// Sizes
//
.image-holder {
  &-xs {
    padding-bottom: 42%;
  }
  &-sm {
    padding-bottom: 60%;
  }
  &-md {
    padding-bottom: 72.5%;
  }
  &-lg {
    padding-bottom: 82.5%;
  }
  &-lg-v {
    padding-bottom: 135%;
  }
  &-xl {
    padding-bottom: 160%;
  }
  &-xxl {
    padding-bottom: 180%;
  }
  &-squere {
    padding-bottom: 100%;
  }
  &-lg-double {
    padding-bottom: 169.5%;
  }
}

//
// Overlay
//
.overlay {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.35s;
    background-color: rgba($black, 0.25);
    opacity: 1;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.35s;
    background-color: rgba($secoundary, 0.45);
    opacity: 0;
  }
}

.overlay:hover {
  &:before {
    opacity: 0;
  }
  &:after {
    opacity: 1;
  }
}

@supports (mix-blend-mode: overlay) {
  .overlay:after {
    mix-blend-mode: multiply;
    background-color: rgba($secoundary, 1);
  }
}

.image-divider {
  display: block;
  position: relative;
  .divider-top,
  .divider-top2,
  .divider-left,
  .divider-bottom {
    position: absolute;
    z-index: 20;
    background-color: $secoundary;
  }
  .divider-top {
    position: absolute;
    width: 60px;
    height: 10px;
    left: 40px;
    top: -5px;
  }
  .divider-top2 {
    height: 10px;
    top: 10px;
    right: calc(100% - 100px);
    width: 0;
    transition: all 0.15s;
  }
  .divider-left {
    left: 10px;
    top: 10px;
    bottom: 10px;
    width: 10px;
    height: 0;
    transition: all 0.2s 0.15s;
  }
  .divider-bottom {
    left: 10px;
    bottom: 10px;
    height: 10px;
    width: 0;
    transition: all 0.2s 0.35s;
  }
}

.image-divider-reverse {
  .divider-bottom {
    transition: all 0.15s;
  }
  .divider-left {
    transition: all 0.2s 0.15s;
  }
  .divider-top2 {
    transition: all 0.2s 0.35s;
  }
  .divider-top {
    transition: all 0.15s 0.45s;
  }
}

.image-divider:hover {
  .divider-top {
    height: 20px;
  }
  .divider-top2 {
    width: 90px;
  }
  .divider-left {
    height: calc(100% - 20px);
  }
  .divider-bottom {
    width: 50%;
  }
}

.holder-zoom {
  .image-holder-bg {
    transform: scale(1);
    transition: all 0.45s;
  }
  &:hover {
    .image-holder-bg {
      transform: scale(1.1);
    }
  }
}

@include media-breakpoint-down(lg) {
  @supports (mix-blend-mode: overlay) {
    .overlay:after {
      mix-blend-mode: multiply;
      background-color: rgba(rgba($secoundary, 0.45), 1);
    }
  }

  .image-holder-content h3 {
    font-size: 18px;
  }
}
