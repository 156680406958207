//
// hero slider
//
.page-hero-slider {
  position: relative;
  top: 10px;
  z-index: 44;

  .page-hero-scroll {
    top: auto;
    bottom: 52%;
  }

  .page-hero-scroll a {
    color: $white;
  }

  h2,
  h3 {
    font-family: "Gotham";
    opacity: 0;
    transform: translateX(-40px);
    color: $white;
  }

  h2 {
    font-size: 72px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.45s;
  }
  h3 {
    font-family: "Gotham Book";
    font-size: 32px;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.55s;
  }

  // swiper active
  .swiper-slide-active {
    h2,
    h3 {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.swiper-nav-background,
.swiper-nav-arrows {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 43%;
  width: 130px;
  height: 190px;
}

.swiper-nav-background {
  mix-blend-mode: multiply;
  background-color: $dark;
}

.swiper-nav-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: block;
    padding: 25px;
    color: $white;
    font-size: 14px;
    &:hover {
      color: $secoundary;
    }
  }
  a:nth-child(1) {
    border-right: 1px solid rgba($white, 0.3);
  }
}

// page content {
.page-content-home {
  position: relative;
  z-index: 88;
  margin-top: -30%;
}

//
// section news
//
.section-news {
  background-color: $black;
  position: relative;
  ul.home-news-list {
    margin: 0;
    padding: 30px 0 30px 0px;
    display: flex;
    position: relative;

    > li {
      flex: 1 1 33.333%;
    }
  }
  .news-item {
    display: flex;
    &:hover {
      color: $secoundary;
      .news-meta,
      a {
        color: $secoundary;
      }
    }
  }
  .news-body,
  .news-body a {
    color: $white;
  }
  .news-image {
    flex-shrink: 0;
    width: 150px;
  }
  .news-body {
    padding: 15px 25px 10px 15px;
  }
  .news-meta {
    transition: all 0.3s;
    font-size: 12px;
    font-weight: 500;
  }
  .news-title {
    font-size: 16px;
  }
  .news-more {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 130px;
    background-color: $dark;
    .inner-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: rotate(-90deg) translateX(30%) translateY(-55%);
      text-align: center;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      [class^="icon"] {
        display: inline-block;
        margin-top: 10px;
      }
    }
  }
  .news-more:hover .inner-text {
    color: $secoundary;
  }
}

//
// section offer
//
.section-offer {
  position: relative;
  padding-top: 90px;
}

ul.offer-list {
  display: flex;
  margin: 0 -15px;

  > li {
    flex: 1 1 50%;
    padding: 0 15px;
  }
}

//
// section portfolio
//

.section-portfolio {
  position: relative;
  z-index: 11;
  margin-top: 80px;
}

.portfolio-video-container {
  position: relative;
  padding-right: 0%;
  margin-bottom: -130px;
  .portfolio-menu-container {
    position: absolute;
    left: 0px;
    right: 0%;
    bottom: -70px;
    border: 8px solid $secoundary;
    border-top: 0px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 8px;
      width: 100%;
      z-index: -1;
      background-color: $secoundary;
    }
  }
}

ul.portfolio-menu {
  position: relative;
  padding-top: 8px;
  padding: 20px 20px 10px 20px;
  width: 420px;
  min-width: 420px;
  max-width: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 1;
  }
  li a {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    padding: 20px 24px 20px 20px;
    border-bottom: 1px solid $gray-200;
    background-color: $white;
    position: relative;
    z-index: 20;

    [class^="icon"] {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      opacity: 0;
      transition: all 0.3s;
      color: $dark;
    }
  }

  li:last-child a {
    border: 0px;
  }

  li a:hover {
    color: $primary;
    background-color: $secoundary;
    color: $black;

    [class^="icon"] {
      opacity: 1;
    }
  }
}

.portfolio-more {
  text-align: right;
  padding-right: 40px;
  padding-bottom: 20px;
  a {
    display: inline-flex;
    align-items: center;
    color: $white;
    font-size: 11px;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    [class^="icon"] {
      font-size: 14px;
      margin-left: 10px;
    }

    &:hover {
      color: $secoundary;
    }
  }
}

//
// section carer
//

.section-career {
  position: relative;
  z-index: 10;
}

.parallax {
  background-color: $black;
  position: relative;
  overflow: hidden;

  .parallax-image {
    position: absolute;
    z-index: 11;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;
  }
  .parallax-overlay {
    position: absolute;
    z-index: 12;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($black, 0.35);
  }
  .parallax-content {
    position: relative;
    z-index: 22;
    color: $white;
    padding: 330px 115px 110px 115px;
    p,
    a:not(.btn) {
      color: $white;
    }
  }
  .parallax-text-content {
    margin-bottom: 50px;
    .title {
      margin-bottom: 50px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

ul.career-list {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
  justify-content: center;
  > li {
    padding: 5px 80px;
    margin-bottom: 5px;
  }
}

.career-item {
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
}

.career-title {
  margin: 100px 0 50px 0;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
}

.career-item-text {
  margin-top: 20px;
  text-transform: uppercase;
}

.career-body {
  width: 800px;
  max-width: 100%;
  p {
    font-size: 15px;
  }
}

// @supports (mix-blend-mode: multiply) {
//   .parallax {
//     .parallax-overlay {
//       background-color: $primary;
//       mix-blend-mode: multiply;
//     }
//   }
// }

@media all and (min-width: 1921px) {
  ul.portfolio-menu {
    // min-width: 640px;
  }
}

@include media-breakpoint-down(xl) {
  // hero slider
  .page-hero-slider {
    h2 {
      font-size: 64px;
    }
    h3 {
      font-family: "Gotham Book";
      font-size: 28px;
    }
  }

  .swiper-nav-background,
  .swiper-nav-arrows {
    bottom: 55%;
    width: 130px;
    height: 190px;
  }

  // page content {
  .page-content-home {
    margin-top: -50%;
  }
  // section news
  .section-news {
    .news-image {
      width: 100px;
    }

    .news-meta {
      margin-bottom: 5px;
    }

    .news-title {
      font-size: 14px;
    }

    .news-body {
      padding-top: 5px;
    }

    ul.home-news-list {
      padding: 30px 0 20px 0px;
      > li {
        padding: 10px 30px;
      }
    }
  }

  // section offer
  .section-offer {
    padding-top: 100px;
  }

  // section portfolio
  ul.portfolio-menu {
    min-width: 360px;
  }
}

@include media-breakpoint-down(lg) {
  // page content {
  .page-content-home {
    margin-top: 0;
  }
  .swiper-nav-background,
  .swiper-nav-arrows {
    bottom: 0;
  }
  // section offer
  .section-offer {
    margin-right: 0;
  }
  ul.offer-list {
    padding: 50px 0px;
  }
  // section portfolio
  .section-portfolio {
    margin-top: 0px;
  }
  .portfolio-video-container {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 40px;
    .portfolio-menu-container {
      position: static;
      &:before {
        content: none;
      }
    }
  }
  ul.portfolio-menu {
    background-color: $white;
    display: block;
    min-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .portfolio-more a {
    color: $dark;
    &:hover {
      color: $primary;
    }
  }

  // section carrer
  .section-career {
    margin-left: 0;
  }
  .career-title {
    font-size: 24px;
    margin: 0px;
  }
  .parallax {
    .parallax-image {
      background-attachment: scroll;
    }
    .parallax-content {
      padding: 100px 0;
    }
  }
}

@include media-breakpoint-down(md) {
  // page gero
  .page-hero-slider {
    top: 0;
    h2 {
      font-size: 26px;
    }
    h3 {
      font-family: "Gotham Book";
      font-size: 24px;
    }
  }

  .swiper-nav-background,
  .swiper-nav-arrows {
    width: 180px;
    height: 125px;
  }

  // section news
  .section-news {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      .row {
        margin-left: 0;
        margin-right: 0;
      }
      [class^="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
    ul.home-news-list {
      display: block;
      margin-left: 0;
      padding: 30px 20px;
      border: 5px solid $secoundary;
      > li {
        padding: 10px 20px;
      }
      &:after {
        content: none;
      }
    }
    .news-more {
      position: static;
      width: 100%;
      .inner-text {
        position: static;
        transform: none;
        width: 100%;
        display: block;
        text-align: center;
        padding-bottom: 20px;
        br {
          display: none;
        }
        [class^="icon"] {
          display: inline-block;
          margin-left: 10px;
          transform: rotate(-90deg);
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  // section hero
  .swiper-nav-background,
  .swiper-nav-arrows {
    width: 140px;
    height: 80px;
  }
  .swiper-nav-arrows a {
    padding: 15px 20px;
  }
  .page-hero-content {
    padding-top: 50px;
  }

  // section news
  .section-news {
    ul.home-news-list {
      display: block;
      padding: 10px 20px;
      > li {
        border-right: 0px;
        border-bottom: 1px solid $gray-200;
      }
      > li:last-child {
        border: 0px;
      }
    }
  }

  // section offer
  .section-offer {
    padding-top: 20px;
  }
  ul.offer-list {
    display: block;
    padding: 0;

    > li:not(last-child) {
      margin-bottom: 20px;
    }
  }

  // section-portfolio
  .section-portfolio {
    padding: 20px 0px;
  }

  // section parallax
  .parallax {
    .parallax-content {
      padding: 50px 0;
    }
    .parallax-text-content {
      width: 100%;
      max-width: 100%;
    }
  }

  // section carrer
  ul.career-list {
    display: block;
    > li :not(last-child) {
      margin-bottom: 20px;
      padding: 5px 0;
    }
    > li a {
      white-space: normal;
    }
  }
  .career-more a {
    margin-top: 0;
  }
}
