//
// Offcanvas
//
.offcanvas {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: $primary;
  transform: translate3d(0%, -100%, 0);
  z-index: 99;
  transition: all 0.4s;
  ul.menu-level {
    padding-top: 90px;
    > li a {
      display: block;
      color: $black;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.9);
    }
  }

  .offcanvas-contact {
    padding: 10px 20px;
    h2,
    h3,
    p,
    a {
      color: $black;
    }
    a {
      display: block;
      font-size: 18px;
      span {
        font-weight: 700;
        margin-right: 5px;
      }
    }
  }

  .offcanvas-socials-menu {
    padding: 10px 20px;
    display: flex;
    margin: 0;
    li {
      padding: 0 10px;
    }
    li:first-child {
      padding-left: 0;
    }
    a {
      color: $black;
      font-size: 28px;
      &:hover {
        color: $white;
      }
    }
  }

  .offcanvas-button {
    padding: 10px 20px;
  }
}

#offcanvas.active,
#offcanvas-reservation.active {
  transform: translate3d(0, 0, 0);
}

@include media-breakpoint-down(lg) {
  body.menu-is-active {
    overflow: hidden;
  }
  .offcanvas {
    overflow-y: auto;
  }
}
