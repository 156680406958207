// 
// page: carrer
// 
ul.job-list{
    margin: 0 -40px;
    > li{
        margin-bottom: 10px;
    }
    a{
        display: block;
        padding: 20px 40px;
        padding-right: 60px;
        position: relative;
        background-color: $white;
        color: $dark;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        border:4px solid $gray-800;
        transition: all 0.25s;
        [class^="icon"]{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    a:hover{
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }
}

.job-back{
    display: block;
    padding: 20px 40px;
    padding-right: 60px;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    border:4px solid $primary;
    transition: all 0.25s;
    background-color: $primary;
    color: $white;
    [class^="icon"]{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover{
        color: $secoundary;
    }
}


.nav-tabs{
    border:0px;
    padding: 20px 0;
    .nav-link{
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        color: $dark;
        border-radius: 0px;
        padding: 20px 40px;
        border:0px;
        border-right: 1px solid $gray-800;
    }
    > li:last-child .nav-link{
        border:0px;
    }
    .nav-link.active{
        color: $primary;
    }
}

.tab-content{
    .list-bullet{
        padding-left: 20px;
    }
}
@include media-breakpoint-down(md) {
    ul.job-list{
        margin-left: 0;
        margin-right: 0;
    }

    .job-back{
        padding-left: 20px;
    }
}

@include media-breakpoint-down(sm) {
    .nav-tabs{
        display: block;
        .nav-link{
            padding: 16px 20px;
            border-right: 0px;
            border-bottom: 1px solid  $gray-800!important;
        }
    }
}

