// 
// Cards
// 
.card {
    border-radius: 0px;
    background-color: $white;
    border: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    transition: all 0.35s;
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s;
    &-body {
        padding: 30px 20px;
        border:1px solid $gray-800;
        border-top: 0px;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0px;
            transition: all 0.2s;
            background-color: $primary;
        }
    }
    .card-date, .card-title{
        transition: all 0.3s;
    }
    &-title{
        margin-bottom: 25px;
        display: block;
        text-shadow:1px 0 0 transparent;
        transition: all 0.15s;
        font-weight: 500;
        font-size: 18px;
    }
    &-meta {
        font-size: 12px;
        font-weight: 500;
    }
    .card-more{
        padding-top: 10px;
        font-size: 12px;
        [class^="icon"]{
            opacity: 0;
            display: inline-block;
            transform: translateX(-20px);
            transition: all 0.25s;
        }
    }
}
.card:hover{
    .card-body:after{
        height: 4px;
    }
    .card-date, .card-title{
        color: $primary;
    }
    .card-more{
        [class^="icon"]{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}


@include media-breakpoint-down(xl) {
    .card .card-title{
        font-size: 20px;
    }
}

@include media-breakpoint-down(lg) {
    .card .card-title{
        font-size: 18px;
    }
    .card .card-body{
        padding: 10px;
    }
    .card-image-left .card-image{
        flex: 0 0 180px;
    }
}

@include media-breakpoint-down(md) {
    .card{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    .card .card-more [class^="icon"]{
        opacity: 1;
        transform: translateX(0px)!important;
    }
    .card-body{
        padding: 20px;
    }
    .card .card-more{
        padding-right: 10px;
    }
}