//
// Buttons
//
.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none !important;
}

button {
  cursor: pointer;
}

.btn {
  font-size: 11px;

  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  padding: 12px 40px;
  color: $dark;
  border: 1px solid transparent;
  outline: 0px;
  box-shadow: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0px;
  text-align: center;
  border-radius: 5px;
  &:active:not(:disabled):not(.disabled),
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled) {
    outline: none;
  }

  [class^="icon"] {
    margin-left: 8px;
    margin-top: -1px;
  }
}

// sizes
.btn-lg {
  padding: 20px 55px;
  font-size: 14px;
}

.btn-white {
  border-color: rgba($white, 0.6);
  color: rgba($white, 0.6);
  background-color: transparent;
  &:active:not(:disabled):not(.disabled),
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &.active:not(:disabled):not(.disabled) {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}

.btn-primary {
  border-color: $secoundary;
  background-color: $secoundary;
  color: $dark;
  &:active:not(:disabled):not(.disabled),
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &.active:not(:disabled):not(.disabled) {
    background-color: $secoundary;
    color: $black;
    border-color: $secoundary;
  }
}

.btn-black {
  border-color: $black;
  background-color: $black;
  color: $white;
  &:active:not(:disabled):not(.disabled),
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &.active:not(:disabled):not(.disabled) {
    background-color: $black;
    color: $black;
    border-color: $black;
  }
}

//
// Link more
//
.link-more {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: 0px;
  padding: 8px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  transition: all 0.3s;
  [class^="icon"] {
    font-size: 12px;
    margin-left: 12px;
  }

  &:hover {
    color: $primary;
  }
}

.btn-arrow {
  width: 90px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: $white;
  position: relative;
  transition: all 0.3s;
  [class^="icon"] {
    font-size: 14px;
  }
  &:hover {
    color: $secoundary;
  }
}

.btn-arrow-left {
  position: relative;
  &:after {
    content: "";
    top: 5px;
    bottom: 5px;
    position: absolute;
    right: 0;
    width: 1px;
    background-color: rgba($white, 0.3);
  }
}
