// 
// page: portfolio
// 
.portfolio-single{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        top: -130px;
        left: -50vw;
        width: 150vw;
        bottom: 120px;
        background-color: $gray-600;
        z-index: -1;
    }
}


.portfolio-single.no-gallery{
    &:after{
        bottom: -40px;
    }
}


@include media-breakpoint-down(md) {
    .portfolio-single.no-gallery{
        &:after{
            bottom: -20px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .portfolio-single{
        &:after{
            content: none;
        }
    }
}