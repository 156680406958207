//
// Bredcrumbs
//
.breadcrumb {
  background-color: transparent;
  border-radius: 0px;
  margin: 0;
  padding: 0px;
  align-items: center;
  &-item,
  &-item a {
    font-size: 12px;
    color: $dark;
    transition: all 0.3s;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
  }
  &-item a:hover {
    color: $black;
  }
  &-item + &-item::before {
    padding-right: 12px;
    padding-left: 12px;
    content: "-";
  }
  &-item.active {
    color: $black;
  }
}

@include media-breakpoint-down(md) {
  .breadcrumb {
    display: none;
  }
}
