//
// page hero
//
.page-hero-container {
  position: relative;
}
.page-hero {
  position: relative;
  padding-bottom: 40%;

  &-image {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($white, 0.35);
    }
  }
  &-content {
    position: absolute;
    z-index: 2;
    padding-top: 10%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 5%;
  }
  &-scroll {
    position: absolute;
    z-index: 20;
    bottom: 160px;
    left: 20px;
    transform: rotate(-90deg) translateX(0%);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    font-family: "Gotham";
    font-weight: bold;
    a {
      font-size: 13px;
      text-transform: uppercase;
      color: $black;
      font-weight: bold;
      [class^="icon"] {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  &-contact {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    right: 0px;
    width: 115px;
    background-color: rgba($black, 0.8);

    .inner-text {
      position: absolute;
      top: 50%;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      color: $white;
      padding-right: 80%;
      a {
        margin-left: 10px;
        color: $secoundary;
        span {
          color: $primary;
          color: $white;
        }
        [class^="icon"] {
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }
}

.page-hero .row {
  max-width: 100%;
}

.page-hero.page-hero-image-large {
  padding-bottom: 70%;

  .page-hero-content {
    padding-top: 14%;
  }

  .page-hero-image:after {
    background-color: rgba($black, 0.35);
  }
}

@include media-breakpoint-down(xl) {
  .page-hero.page-hero-image-large {
    padding-bottom: 90%;

    .page-hero-content {
      padding-top: 250px;
    }
  }

  .page-hero-scroll {
    display: none;
  }

  .page-hero-contact {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .page-hero.page-hero-image-large {
    padding-bottom: 60%;
  }

  .page-hero {
    &-scroll,
    &-contact {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-hero,
  .page-hero.page-hero-image-large {
    padding-bottom: 72.5%;
  }

  .section-news {
    .news-body {
      padding: 0;
      padding-left: 10px;
    }

    ul.home-news-list > li {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .page-hero,
  .page-hero.page-hero-image-large {
    padding-bottom: 120%;
  }

  .page-hero.page-hero-image-large {
    .page-hero-content {
      padding-top: 25%;
    }
  }
}
