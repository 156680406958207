//
// FORM CONTROL
//
.form-control {
    border-radius: 0px;
    font-size: 13px;
    padding: 12px 20px;
    padding-left: 40px;
    height: 60px;
    font-weight: 300;
    font-size: 16px;
    color: $dark;
    background-color: $white;
    border: 1px solid $gray-200;
    color: $dark;
}

.form-control:focus {
    background-color: $white;
    box-shadow: none;
    border-color: $primary;
}

.form-control:disabled, .form-control[readonly]{
    background-color: $white;
}

textarea.form-control {
    height: auto;
    line-height: 1.3;
}

label:not(.custom-control-label){
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba( $dark, 0.6);
    padding-left: 40px;
}


.required-label{
    text-align: right;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
}

.reguired-py{
    padding: 35px 0;
}

//
// FORM GROUP
//
.form-group {
    position: relative;
    margin-bottom: 25px;
}

.form-group-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        flex: 0 0 auto;
        margin-left: 20px;
    }
}

.form-flex{
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    p{
        font-size: 11px;
        text-transform: uppercase;
        color: rgba($dark, 0.7);
        font-weight: 500;
        margin: 0;
    }
}

//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    color: $dark;
}

input ::-moz-placeholder {
    color:  $dark;
}

input :-ms-input-placeholder {
    color:  $dark;
}

input :-moz-placeholder {
    color:  $dark;
}


//
// CUSTOM RADIO, CUSTOM CHECKBOXES
//
.custom-checkbox{
    padding-left: 45px;
    padding-top: 10px;
    a{
        color: $primary;
    }

    a, label {
        padding-left: 20px;
    }
}
.custom-control-label::before{
    content: none;
}
.custom-control-label::after{
    width: 29px;
    height: 30px;
    background-image: url(../img/checkbox.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-image: url(../img/checkbox.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

.has-danger {
    .custom-control-label::after{
        background-position: bottom center;
    }
}

//
// bootstap select
//
.bootstrap-select{
    width: 100%!important;
    text-transform: none;
    > .dropdown-toggle{
        background-color: $white!important;
        height: 60px;
        line-height: 60px;
        font-size: 13px;
        font-weight: 500;
        text-transform: none;
        border: 1px solid #cecece;
        .filter-option{
            line-height: 60px;
            padding: 0;
            padding-left: 20px;
        }

        &:hover{
            border-color: #cecece;
        }
    }

    >.dropdown-toggle[aria-expanded="true"]{
        border-color: $primary;
    }

    >.dropdown-toggle:active, >.dropdown-toggle.active{
        background-color: $white!important;
        border-color: $primary;

    }
    >.dropdown-toggle:focus{
        outline: none!important;
        box-shadow: none!important;
        border-color: $primary;
    }

    .dropdown-item.active, .dropdown-item:active{
        background-color: $primary;
    }

    >.dropdown-toggle::after{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        border:0px;
        content: "\e917";
        font-family: 'icomoon' !important;
        font-size: 8px;
        color: $dark;
        width: auto;
        height: auto;
        line-height: 1;
    }
}

.bootstrap-select .dropdown-menu{
    border-radius: 0px;
    padding: 0;
    .dropdown-item{
        padding: 8px 20px;
        font-size: 13px;
        font-weight: 500;
    }
}


//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    label:before {
        border-color: $danger;
    }
}
.bootstrap-select > .dropdown-toggle.form-control-danger{
    border-color: $danger;
}

//
// [data-filter]
//
.data-filter-group div[data-filter] {
    display: none;
}

.data-filter-group div[data-filter].active {
    display: block;
}


//
// form wizard
//
.form-wizzard-section.current {
    display: inherit;
}
.form-wizzard-section {
    display: none;
}


//
// Bootstrap datapicker
//
.input-datapicker{
    position: relative;
    &:after{
        content: "\e906";
        font-family: 'icomoon' !important;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        pointer-events: none;
    }
}

.datepicker{
    border-radius: 0px;
}

.datepicker td, .datepicker th{
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    height: 30px;
}

.datepicker table tr td.active{
    background-image: none;
    background-color: $primary!important;
}

.date-placeholder{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 5px;
    display: none;
    left: 0px;
    top: 5px;
    position: absolute;
    left: 25px;
    top: 10px;
    pointer-events: none;
}

.datepicker-mobile{
    input[type="date"]{
        padding-top: 20px;
    }
    .date-placeholder{
        display: block;

    }
}

//
// custom File
//
.custom-file{
    padding-left: 0!important;
    .custom-file-input{
        position: absolute;
        z-index: -1;
        opacity: 0;
        height: 0;
    }
}
.form-group-file{
    border:0px;
    padding: 0;
    .input-group{
        border: 1px solid $gray-200;
        padding: 20px 20px;
        padding-left: 40px;
        display: flex;
        align-items: center;
        label{
            margin: 0;
            font-size: 14px;
        }
    }

    .custom-file{
        display: flex;
        align-items: center;
        height: auto;
    }

    .input-file-label{
        font-size: 16px;
        font-weight: 300;
        text-transform: none;
    }

    .input-file-btn{
        white-space: nowrap;
        padding-left: 40px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        letter-spacing: 2px;
        cursor: pointer;
        font-size: 12px;
        color: $dark;
        [class^="icon"]{
            margin-left: 10px;
            font-size: 14px;
        }
    }
}

.has-danger{
    .input-group{
        border-color: red;
    }
}

// form container
.form-conatiner{
    .form-title{
        padding: 40px 0;
        padding-left: 40px;
    }
    .form-title-primary{
        color: $primary;
        font-size: 32px;
    }
}

@include media-breakpoint-down(md) {
    .form-group-flex {
        display: block;
    }

    .form-row-icons{
        padding-right: 60px;
    }

    [data-copy]{
        right: 40px;

    }
    [data-remove]{
        right: 10px;
    }
}


@include media-breakpoint-down(md) {
    .form-flex{
        display: block;
        .btn{
            margin-top: 20px;
        }
    }

    .form-group-file .custom-file{
        display: block;
    }
    .form-group-file .input-file-btn{
        justify-content: flex-end;
        padding: 15px 0;
    }

    .form-conatiner .form-title{
        padding: 20px 0;
    }

    label:not(.custom-control-label){
        padding-left: 10px;
    }
}