@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-y1:before {
    content: "\e91e";
  }
  .icon-y2:before {
    content: "\e91f";
  }
  .icon-y3:before {
    content: "\e920";
  }
  .icon-y4:before {
    content: "\e921";
  }
  .icon-y5:before {
    content: "\e922";
  }
  .icon-y6:before {
    content: "\e923";
  }
  .icon-envelope:before {
    content: "\e91c";
  }

  .icon-envelope-2:before {
    content: "\e91c";
  }
  .icon-smartphone-call:before {
    content: "\e91d";
  }

  .icon-file-empty:before {
    content: "\e924";
  }
  .icon-files-empty:before {
    content: "\e925";
  }
  .icon-file-text2:before {
    content: "\e926";
  }
  .icon-file-picture:before {
    content: "\e927";
  }
  .icon-file-music:before {
    content: "\e928";
  }
  .icon-file-play:before {
    content: "\e929";
  }
  .icon-file-video:before {
    content: "\e92a";
  }
  .icon-file-zip:before {
    content: "\e92b";
  }
  .icon-copy:before {
    content: "\e92c";
  }
  .icon-warning:before {
    content: "\ea07";
  }
  .icon-notification:before {
    content: "\ea08";
  }
  .icon-plus:before {
    content: "\ea0a";
  }
  .icon-minus:before {
    content: "\ea0b";
  }
  .icon-cancel-circle:before {
    content: "\ea0d";
  }
  .icon-cross:before {
    content: "\ea0f";
  }
  .icon-checkmark:before {
    content: "\ea10";
  }
  .icon-checkmark2:before {
    content: "\ea11";
  }
  .icon-play3:before {
    content: "\ea1c";
  }
  .icon-pause2:before {
    content: "\ea1d";
  }
  .icon-google3:before {
    content: "\ea8a";
  }
  .icon-google-plus:before {
    content: "\ea8b";
  }
  .icon-facebook1:before {
    content: "\ea90";
  }
  .icon-facebook2:before {
    content: "\ea91";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-whatsapp:before {
    content: "\ea93";
  }
  .icon-telegram:before {
    content: "\ea95";
  }
  .icon-rss:before {
    content: "\ea9b";
  }
  .icon-rss2:before {
    content: "\ea9c";
  }
  .icon-youtube2:before {
    content: "\ea9e";
  }
  .icon-behance:before {
    content: "\eaa8";
  }
  .icon-behance2:before {
    content: "\eaa9";
  }
  .icon-tumblr:before {
    content: "\eab9";
  }
  .icon-tumblr2:before {
    content: "\eaba";
  }
  .icon-linkedin:before {
    content: "\eac9";
  }
  .icon-linkedin2:before {
    content: "\eaca";
  }
  .icon-lastfm:before {
    content: "\eacb";
  }
  .icon-lastfm2:before {
    content: "\eacc";
  }
  .icon-file-pdf:before {
    content: "\eadf";
  }
  .icon-file-openoffice:before {
    content: "\eae0";
  }
  .icon-file-word:before {
    content: "\eae1";
  }
  .icon-file-excel:before {
    content: "\eae2";
  }
  .icon-libreoffice:before {
    content: "\eae3";
  }
  .icon-arrow-down:before {
    content: "\e900";
  }
  .icon-arrow-left:before {
    content: "\e901";
  }
  .icon-arrow-right:before {
    content: "\e902";
  }
  .icon-arrow-up:before {
    content: "\e903";
  }
  .icon-check:before {
    content: "\e904";
  }
  .icon-close:before {
    content: "\e905";
  }
  .icon-cross-out:before {
    content: "\e906";
  }
  .icon-down-arrow:before {
    content: "\e907";
  }
  .icon-download-s:before {
    content: "\e908";
  }
  .icon-download:before {
    content: "\e909";
  }
  .icon-envelope:before {
    content: "\e90a";
  }
  .icon-facebook-logo:before {
    content: "\e90b";
  }
  .icon-facebook:before {
    content: "\e90c";
  }
  .icon-form:before {
    content: "\e90d";
  }
  .icon-full-screen:before {
    content: "\e90e";
  }
  .icon-instagram-logo:before {
    content: "\e90f";
  }
  .icon-magnifying-glass:before {
    content: "\e910";
  }
  .icon-next:before {
    content: "\e911";
  }
  .icon-phone-call:before {
    content: "\e912";
  }
  .icon-pin:before {
    content: "\e913";
  }
  .icon-play:before {
    content: "\e914";
  }
  .icon-prev:before {
    content: "\e915";
  }
  .icon-search:before {
    content: "\e916";
  }
  .icon-tr-down:before {
    content: "\e917";
  }
  .icon-tr-left:before {
    content: "\e918";
  }
  .icon-tr-right:before {
    content: "\e919";
  }
  .icon-tr-up:before {
    content: "\e91a";
  }
  .icon-zoom:before {
    content: "\e91b";
  }
