// colors
$dark: #353434;
$black: #000;
$white: #fff;
// $primary: #e75e09;
$primary: #ffec09;
$secoundary: #ffec09;

$gray-100: #f8f8f8;
$gray-200: #e5e5e5;
$gray-300: #969393;
$gray-400: #a8a7a7;
$gray-500: #b0b0b1;
$gray-600: #f6f6f6;
$gray-700: #b5b5b5;
$gray-800: #dadada;
