//
// about page
//

.parallax.parallax-about {
  margin-top: 120px;
  .parallax-content {
    padding: 150px 0;

    img {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  .parallax.parallax-about {
    margin-top: 40px;
    .parallax-content {
      padding: 40px 0;

      img {
        margin-top: 20px;
      }
    }
  }
}
