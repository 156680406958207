//
// page: security
//

.security {
    h2 {
        margin-left: 55px;
        margin-bottom: 50px;
    }
    p {
        margin: 20px 0px 40px 0px;
    }
    ol {
        counter-reset: item;
        color: $dark;
        padding-left: 40px;
        margin-bottom: 20px;

        li {
            display: block;
        }

        li > ol > li {
            padding-left: 30px;
            margin-top: 4px;
        }

        a {
            color: $dark;
            &:hover {
                text-decoration: underline;
                color: $primary;
            }
        }



        > li:before {
            content: counters(item, ".") ".\00a0 ";
            counter-increment: item;
            float: left;
        }

        > li > ol > li:before {
            content: counters(item, ".") "\00a0";
            counter-increment: item;
            float: left;
        }

        li {
            margin-bottom: 6px;
        }

        li a[href$=".pdf"] {
            position: relative;
            padding-right: 30px;
        }

        li a[href$=".pdf"]:after, :root .pdf-link {
            // content: "\eadf";
            // font-family: 'icomoon' !important;
            // font-size: 16px;
            // color: $primary;
            // margin-left: 6px;
            // position: absolute;
            // bottom: -3px;
            // right: 0px;

            content: "";
            position: absolute;
            top: -8px;
            left: -55px;
            background-image: url(../img/pdf.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            width: 32px;
            height: 32px;
        }
    }

    .security-ruless {
        text-align: center;
        p {
            margin-top: 0;
        }
    }

    ol.hide-counter {
        > li:before {
            content: none;
        }

        li a[href$=".pdf"]:after, :root .pdf-link {
            left: -35px;
        }
    }
}


@include media-breakpoint-down(md) {
    .security {
        h2 {
            margin-left: 0;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
        }

        .security-ruless {
            text-align: left;
            p {
                margin-top: 0;
            }
        }
    }
}