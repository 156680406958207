//
// Pagination
//

.pagination {
    display: inline-flex;
    margin: 0;
    li a {
        padding: 5px 7px;
        font-size: 12px;
        font-weight: 400;
        transition: all 0.3s;
        color: $dark;
        &:hover {
            color: $primary;
        }
    }
    li a.active {
        color: $primary;
        font-weight: 700;
    }
}