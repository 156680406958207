//
// plyr
//

video{
    object-fit: inherit;
}

.plyr__video-wrapper{
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
}


.plyr__control--overlaid{
    border-radius: 0px;
    padding: 0;
    background-color: transparent!important;
}

.plyr__controls{
    opacity: 0;
}

.plyr__controls{
    top: 0;
    bottom: auto!important;
    padding-top: 20px!important;
}

[data-custom-poster]{
    background-size: cover;
    background-position: 50%;
}

.plyr--playing .plyr__controls{
    opacity: 1;
}

.plyr__control--overlaid svg{
    display: none;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid{
    &:after{
        content: "\e914";
        font-family: 'icomoon' !important;
        color: $white;
        font-size: 164px;
    }
}


@include media-breakpoint-down(xl) {
    .plyr--full-ui.plyr--video .plyr__control--overlaid{
        &:after{
           font-size: 110px;
        }
    }
}

@include media-breakpoint-down(lg){
    .plyr__controls{
        top: auto!important;
        bottom: 0!important;
        padding-top: 0px!important;
        padding-bottom: 20px!important;
        opacity: 1;
    }
    .plyr--video .plyr__controls{
        position: static;
        background-color: $dark;
        padding: 20px 10px!important;
    }

    .plyr--video.plyr--hide-controls .plyr__controls{
        transform: translateY(0px);
        opacity: 1;
    }
    .plyr--full-ui.plyr--video .plyr__control--overlaid{
        top: 40%;
    }
}


@include media-breakpoint-down(sm){
    .plyr--full-ui.plyr--video .plyr__control--overlaid{
        &:after{
           font-size: 54px;
        }
    }
}



