// 
// page: offer
// 

.offer-list-page{
    > li{
        margin: 140px 0;
    }
}
.offer-list-portfolio{
    > li:first-child{
        margin-top: 30px;
    }
}
.offer-content{
    h3{
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    p:last-of-type{
        margin-bottom: 35px;
    }
}

.portfolio-single{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        top: -110px;
        left: -50vw;
        width: 150vw;
        bottom: 120px;
        background-color: $gray-600;
        z-index: -1;
    }
}


.hero-text{
    padding: 80px 0;
    h3{
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

.row-hero-text ~ .row-offer ul.offer-list-page > li:first-child{
    margin-top: 0;
}

@include media-breakpoint-down(md) {
    .offer-list-page{
        > li{
            margin: 20px 0;
            margin-bottom: 40px;
        }
    }
    .offer-list-portfolio{
        > li:first-child{
            margin-top: 0px;
        }
    }
    
    .offer-content{
        h3{
            margin: 20px 0px;
        }
        p:last-of-type{
            margin-bottom: 20px;
        }
    }

    .hero-text{
        padding: 20px 0;
        h3{
            margin-bottom: 10px;
        }
    }
}